* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    max-width: 1800px;
    margin: 0 auto;
}

h2 {
    color: #E87722;
    font-size: 40px;
    text-align: center;
}

.btn-open, .btn-close {
    display: none;
}

.container {
    margin-top: 72px;
    display: flex;
}

.tab {
    overflow: auto;
    width: 20% ;
    height: 100vh;
    background-color: #E87722;
    position: sticky;
    top: 0;
}

.tab button {
    width: 100%;
    padding: 20px;
    background-color: #E87722;
    color: white;
    font-size: 24px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.tab button:hover {
    background-color: white;
    color: #E87722;
}

.tab button .active {
    background-color: white;
    color: #E87722;
}

.glossary {
    width: 80%;
    padding: 50px 0;
}

@media screen and (max-width: 550px) {
    h2 {
        font-size: 32px;
    }

    .btn-open {
        display: block;
        padding: 8px 10px;
        color: white;
        background-color: #E87722;
        border: none;
        border-radius: 100%;
        position: fixed;
        top: 90px;
        left: 15px;
        cursor: pointer;
        font-size: 18px;
    }

    .btn-close {
        display: block;
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        text-decoration: none;
        color: black;
    }

    .tab {
        width: 0;
        position: fixed;
        z-index: 1;
        height: 100vh;
        top: 0;
        left: 0;
        overflow-x: hidden;
        transition: 0.5s;
    }

    .glossary {
        width: 100%;
        padding: 50px 0;
    }
}