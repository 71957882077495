.glossary-list {
    list-style-image: url('/src/assets/point.png');
    font-size: 20px;
    padding: 0 15%;
    text-align: justify;
}

.glossary-list > li {
    padding: 70px 0 0 0;
    position: relative;
}

.glossary-item {
    color: #E87722;
    font-weight: bold;
}

.text-small {
    font-size: 18px;
    font-style: italic;
}

@media screen and (max-width: 550px) {
    .glossary-list {
        padding: 50px 8% 0 13%;
        font-size: 18px;
    }

    .text-small {
        font-size: 16px;
    }
}