* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

hr {
    width: 100px;
    height: 10px;
    background-color: #E87722;
    border: none;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    margin: 100px 0;
}

h2 {
    text-align: center;
    color: #E87722;
    /* font-size: 40px; */
}

.imagen_portada {
    text-align: center;
    margin-top: 7rem;
}

.imagen_portada img {
    width: 500px;
}

.sec-body-content-01, .sec-body-content-02 {
    width: 80%;
    max-width: 1500px;
    display: flex;
    align-items: center;
    gap: 50px;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.sec-body-content-column-text {
    width: 60%;
    border-left: solid 5px #E87722;
    padding-left: 15px;
}

.sec-body-content-column-pill {
    width: 40%;
}

.sec-body-content-text {
    /* font-size: 24px; */
    color: #202020;
    text-align: justify;
}

.info-pill {
    border: solid 2px #E87722;
    border-radius: 60px;
    padding: 20px 30px;
}

.info-pill:hover {
    box-shadow: 
    4px 4px 10px #00000025,
    4px -4px 10px #00000025,
    -4px 4px 10px #00000025,
    -4px -4px 10px #00000025;   
}

.info-pill-title {
    display: flex;
    align-items: center;
    gap: 10px;
    /* font-size: 30px; */
    color: #E87722;
}

.info-pill-text {
    /* font-size: 16px; */
    margin: 10px 0;
    color: #202020;
}

.info-pill-link-container {
    display: flex;
    justify-content: right;
}

.info-pill-link {
    display: flex;
    align-items: center;
    gap: 10px;
    border: solid 2px #E87722;
    border-radius: 30px;
    padding: 0 10px;
    font-weight: bold;
    /* font-size: 16px; */
    text-decoration: none;
    color: #E87722;
}

.info-pill-link:hover {
    box-shadow: 
    4px 4px 10px #00000025,
    4px -4px 10px #00000025,
    -4px 4px 10px #00000025,
    -4px -4px 10px #00000025;   
}

.sec-credits {
    width: 80%;
    max-width: 1500px;
    margin: 50px 0;
    display: flex;
    gap: 50px;
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.credits-list {
    list-style-image: url('../assets/point.png');
}

.credits-list > li {
    padding: 5px 0 10px 0;
    /* font-size: 20px; */
}

.credits-name {
    color: #E87722;
    font-weight: bold;
}

@media screen and (max-width: 650px) {

    .imagen_portada img {
        width: 300px;
    }

    .sec-body-content-01 {
        flex-direction: column;
    }

    .sec-body-content-02 {
        flex-direction: column-reverse;
    }

    .sec-body-content-column-text, .sec-body-content-column-pill {
        width: 100%;
    }

    .sec-credits {
        flex-direction: column;
        gap: 0;
    }
}